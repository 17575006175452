// src/components/UserNotFound.js
import React from 'react';
import '../Style/YourCertificate.css';

const YourCertificate = () => {
  return (
   
   
        <div class="certificate-label">
        Your Certificate
        </div>
      
  );
};

export default YourCertificate;
