import React, { useState, useEffect } from 'react';
import {Card, CardContent, Box } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, useParams,useLocation, useNavigate } from 'react-router-dom';
import CertificateForm from './components/CertificateForm';
import UserNotFound from './components/Usernotfound'
import Header from './components/Header';
import PDFViewer from './components/PDFViewer';
import YourCertificate from './components/YourCertificate'

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<CertificateFormWrapper />} />
        <Route path="/certificate" element={<CertificateDetails />} />
      </Routes>
    </Router>
  );
};

const CertificateFormWrapper = () => {
  const navigate = useNavigate();
  const handleSubmit = (certificateId) => {
    // navigate(`/certificate/${certificateId}`);
    navigate(`/certificate?certificate_id=${certificateId}`);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <CertificateForm onSubmit={handleSubmit} />
      </CardContent>
    </Card>
  );
};

const CertificateDetails = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const certificateId = query.get('certificate_id');

  const [user, setUser] = useState(null);
  const [showPDF, setShowPDF] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [userNotFound, setuserNotFound] = useState(false);

  useEffect(() => {
    const PDF_Api = process.env.REACT_APP_PDF_API_URL;
    const fetchUserDetails = async () => {
      try {
        const Api = process.env.REACT_APP_API_URL;
        const response = await fetch(`${Api}?_id=${certificateId}`);
        if (response.ok) {
          const data = await response.json();
          setUser(data);
          setShowPDF(true);
          setPdfUrl(`${PDF_Api}certificate?certificate_id=${certificateId}`); // Correct PDF URL
          setuserNotFound(false);
        } else {
          setUser(null);
          setuserNotFound(true);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        alert('Failed to fetch user details');
        // setShowPDF(true); // remove this line once DB is up and running
        // setPdfUrl(`${PDF_Api}certificate?certificate_id=${certificateId}`); // Correct PDF URL /
      }
    };

    fetchUserDetails();
  }, [certificateId]);

  return (
    <>
    {userNotFound && <UserNotFound />}
     { showPDF &&(
        <>
          <YourCertificate/>
          <PDFViewer url={pdfUrl} certificateId={certificateId} user={user}/> {/* Pass the URL prop */}
        </>
      )}
    </>
  );
};

export default App;
